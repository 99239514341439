<template>
    <default-layout class="authPage">
        <div class="pageBody">
            <h1 class="pageTitle">Sign up</h1>
            <h2 class="pageSubtitle">for a free scoop!</h2>
            <google-login-button @success="signup">
                Sign up with Google
            </google-login-button>
            <p>
                Already have an account?
                <router-link :to="{ name: 'login' }">Log in</router-link>
            </p>
        </div>
    </default-layout>
</template>

<script>
import GoogleLoginButton from '@/components/Button/GoogleLoginButton'
import { googleAuth } from '~/mixins/googleAuth'

export default {
    name: 'Signup',
    components: { GoogleLoginButton },
    mixins: [googleAuth],
}
</script>
