<template>
    <div class="gBtn" role="button" ref="btn">
        <i class="gBtnIco"></i>
        <span class="gBtnTxt"><slot>Log in with Google</slot></span>
    </div>
</template>

<script>
let auth2 = null

export default {
    name: 'GoogleLoginButton',
    mounted() {
        window.gapi.load('auth2', () => {
            if (!auth2) {
                auth2 = window.gapi.auth2.init({
                    client_id: `${process.env.VUE_APP_GOOGLE_SIGNIN_CLIENT_ID}`,
                })
            }
            this.$refs.btn &&
                auth2.attachClickHandler(
                    this.$refs.btn,
                    {},
                    user => {
                        // Success
                        const idToken = user.getAuthResponse().id_token
                        this.$emit('success', idToken)
                    },
                    () => {
                        // Failure
                    }
                )
        })
    },
}
</script>
